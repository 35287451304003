<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="display-3">
                            {{ pageTitle }}
                        </h2>
                    </div>
                    <div class="col-lg-12">
                        <material-card
                            v-for="item in items" 
                            :key="item.ID"
                            :mid="item.ID.toString()"
                            :title="item.Title"
                            :pdfUrl="item.PdfPath"
                            :zipUrl="item.ZipPath"
                            :sibUrl="item.SibPath"
                            :mp3Url="item.Mp3Path"
                            :createdAt="item.CreatedAt"
                            :report="report"
                            :playSound="playSound"
                            :pauseSound="pauseSound"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import MaterialCard from "./components/MaterialCard";

export default {
    name: "Materials",
    components: {
        MaterialCard
    },
    data () {
        return {
            items: '',
            pageTitle: '',
            audio: null
        };
    },
    beforeMount () {
        this.getPageTitle();
        this.get_materials();
    },
    methods: {
        get_materials () {
            this.setHeaders();

            const url_list = window.location.href.split('-');

            axios.get(this.$apiUrl + "/get_materials",
                {
                    params: {
                        category: url_list[url_list.length - 1],
                        active: true
                    }
                }
            ).then(response => {
                this.items = response.data;
            });
        },

        // Set the headers for any API requests
        setHeaders() {
            axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
        },

        // Different page titles
        getPageTitle() {
            const page = window.location.href.split('-')[1];

            const titles = {
                orchestra: 'Ноты Духового Оркестра',
                learning: 'Учебный Материал',
                choir: 'Хоровые ноты'
            };

            this.pageTitle = titles[page];

        },
        playSound(title, sound) {
            if (sound) {

                this.$gtag.event('Play', {
                    method: 'Google',
                    event_category: 'Audio',
                    event_label: title
                });

                this.pauseSound();
                this.audio = new Audio(sound);
                this.audio.play();

            }
        },
        pauseSound() {
            if (this.audio) {
                this.audio.pause();
            }
        },
        report(title, filetype) {

            this.$gtag.event(filetype, {
                method: 'Google',
                event_category: 'Downloads',
                event_label: title
            });

        }
    }
};

</script>